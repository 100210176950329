import { StaticImage } from "gatsby-plugin-image"
import React, { useContext } from "react"
import '../styles/pages.scss'
import { texts } from "../texts"
import { Context } from "../components/Context"

const SectionsPage = () => {
  const { lang } = useContext(Context)
  const [showBackToTop, setShowBackToTop] = React.useState(false)

  const showHideBackToTop = () => {
    if (window.scrollY >= window.innerHeight) setShowBackToTop(true)
    else setShowBackToTop(false)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', showHideBackToTop)

    return () => {
      window.removeEventListener('scroll', showHideBackToTop)
    }
  }, [])

  return (
    <main id='sections'>
      <a className={`backtotop ${showBackToTop ? '' : 'hidden'}`} href="#section-aire"></a>
      <section id="section-aire">
        <div className="slide title">
          <h1 className="heading">{texts[lang].aire.title}</h1>
        </div>
        <div className="slide">
          <div className="text w50 top right">
            {texts[lang].aire.data[0][0]} <span className="bold">{texts[lang].aire.data[0][1]}</span>
          </div>
        </div>
        <div className="slide bg-black">
          <div className="text w50 right">
            {texts[lang].aire.data[1][0]} <span className="color">{texts[lang].aire.data[1][1]}</span>
          </div>
        </div>
        <div className="slide bg-lightgray split">
          <StaticImage className="slide__image" src="../images/aire3.jpg" alt="barcelona92" />
          <div className="text top right">
            {texts[lang].aire.data[2][0]} <span className="bold">{texts[lang].aire.data[2][1]}</span>
          </div>
        </div>
        <div className="slide bg-green">
          <div className="text w35 left">
            <span className="bold">{texts[lang].aire.data[3][0]}</span> {texts[lang].aire.data[3][1]}
          </div>
        </div>
        <div className="slide bg-yellow">
          <div className="text w70">
            <span className="bold">{texts[lang].aire.data[4][0]}</span> {texts[lang].aire.data[4][1]}
          </div>
        </div>
      </section>

      <section id="section-clima">
        <div className="slide title">
          <h1 className="heading">{texts[lang].clima.title}</h1>
        </div>
        <div className="slide bg-lightgray split">
          <div className="text left">
            <span className="bold">{texts[lang].clima.data[0][0]}</span> {texts[lang].clima.data[0][1]}
          </div>
          <StaticImage className="slide__image" src="../images/clima1.jpg" alt="barcelona92" />
        </div>
        <div className="slide bg-darkgray">
          <div className="text w50 right">
            {texts[lang].clima.data[1][0]} <span className="color">{texts[lang].clima.data[1][1]}</span>
          </div>
        </div>
        <div className="slide bg-yellow split">
          <StaticImage className="slide__image" src="../images/clima3.jpg" alt="barcelona92" />
          <div className="text right">
            {texts[lang].clima.data[2][0]} <span className="bold">{texts[lang].clima.data[2][1]}</span>
          </div>
        </div>
        <div className="slide">
          <div className="text w70">
            <span className="bold">{texts[lang].clima.data[3][0]}</span> {texts[lang].clima.data[3][1]}
          </div>
        </div>
        <div className="slide bg-blue">
          <div className="text w50 right">
            {texts[lang].clima.data[4][0]} <span className="bold">{texts[lang].clima.data[4][1]}</span>
          </div>
        </div>
      </section>

      <section id="section-movilidad">
        <div className="slide title">
          <h1 className="heading">{texts[lang].movilidad.title}</h1>
        </div>
        <div className="slide split">
          <div className="text left">
            <span className="bold">{texts[lang].movilidad.data[0][0]}</span>
          </div>
          <StaticImage className="slide__image" src="../images/movilidad1.jpg" alt="barcelona92" />
        </div>
        <div className="slide bg-lightgray">
          <div className="text w50 right">
            {texts[lang].movilidad.data[1][0]} <span className="bold">{texts[lang].movilidad.data[1][1]}</span>
          </div>
        </div>
        <div className="slide bg-black">
          <div className="text w70">
            <span className="color">{texts[lang].movilidad.data[2][0]}</span> {texts[lang].movilidad.data[2][1]}
          </div>
        </div>
        <div className="slide">
          <div className="text w50 right">
            {texts[lang].movilidad.data[3][0]} <span className="bold">{texts[lang].movilidad.data[3][1]}</span>
          </div>
        </div>
        <div className="slide bg-yellow split">
          <div className="text left">
            <span className="bold">{texts[lang].movilidad.data[4][0]}</span> {texts[lang].movilidad.data[4][1]}
          </div>
          <StaticImage className="slide__image" src="../images/movilidad5.jpg" alt="barcelona92" />
        </div>
      </section>

      <section id="section-espacio">
        <div className="slide title">
          <h1 className="heading">{texts[lang].espacio.title}</h1>
        </div>
        <div className="slide bg-darkgray split">
          <div className="text bottom left">
            {texts[lang].espacio.data[0][0]} <span className="color">{texts[lang].espacio.data[0][1]}</span>
          </div>
          <StaticImage className="slide__image" src="../images/espacio1.jpg" alt="barcelona92" />
        </div>
        <div className="slide">
          <div className="text w35 left">
            {texts[lang].espacio.data[1][0]} <span className="bold">{texts[lang].espacio.data[1][1]}</span>
          </div>
        </div>
        <div className="slide bg-green split">
          <StaticImage className="slide__image" src="../images/espacio3.jpg" alt="barcelona92" />
          <div className="text right">
            <span className="bold">{texts[lang].espacio.data[2][0]}</span> {texts[lang].espacio.data[2][1]}
          </div>
        </div>
        <div className="slide bg-black">
          <div className="text w70">
            {texts[lang].espacio.data[3][0]} <span className="bold">{texts[lang].espacio.data[3][1]}</span>
          </div>
        </div>
        <div className="slide bg-red">
          <div className="text w50 left">
            {texts[lang].espacio.data[4][0]} <span className="bold">{texts[lang].espacio.data[4][1]}</span>
          </div>
        </div>
      </section>

      <section id="section-ruido">
        <div className="slide title">
          <h1 className="heading">{texts[lang].ruido.title}</h1>
        </div>
        <div className="slide bg-red">
          <div className="text w70">
            {texts[lang].ruido.data[0][0]}
          </div>
        </div>
        <div className="slide">
          <div className="text w50 bottom left">
            <span className="bold">{texts[lang].ruido.data[1][0]}</span> {texts[lang].ruido.data[1][1]}
          </div>
        </div>
        <div className="slide bg-black split">
          <StaticImage className="slide__image" src="../images/ruido3.jpg" alt="barcelona92" />
          <div className="text">
            {texts[lang].ruido.data[2][0]} <span className="bold">{texts[lang].ruido.data[2][1]}</span>
          </div>
        </div>
        <div className="slide bg-yellow">
          <div className="text w50 left">
            <span className="bold">{texts[lang].ruido.data[3][0]}</span> {texts[lang].ruido.data[3][1]}
          </div>
        </div>
        <div className="slide bg-lightgray">
          <div className="text w50 right">
            {texts[lang].ruido.data[4][0]} <span className="bold">{texts[lang].ruido.data[4][1]}</span>
          </div>
        </div>
      </section>

    </main>
  )
}

export default SectionsPage
